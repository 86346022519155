<template>
    <div style="width: 100%; background: #000;">
        <WaveDivider style="position: absolute; width: 100%" position="top" foreground="gradient" background="transparent" />
        <img id="goals-bg" class="goals-bg" v-bind:src="goals[currentGoal].image" />
        <div class="goals-inner" style="padding-top: 32px">
            <div class="subsection subsection-head mob-center" style="background: transparent !important;">
                <h4 style="opacity: 0.75"><i class="fa fa-heartbeat"></i> Main Goals</h4>
                <div id="goal-animator">
                    <h2>{{goals[currentGoal].title}}</h2>
                    <span class="underbar"></span>
                    <p style="line-height: 28px" v-html="goals[currentGoal].content"></p>
                </div>
                
                <div class="carousel-select">
                    <span v-for="(goal, index) in goals" v-on:click="selectGoal(index)" v-bind:key="index" v-bind:selected="currentGoal==index"></span>
                </div>
            </div>
            <WaveDivider position="bottom" foreground="#FFFFFF" background="transparent" />
        </div>
    </div>
</template>

<script>
    import WaveDivider from '@/components/WaveDivider.vue'
    let goalImages = [
        require('@/assets/images/image_placeholder3.jpg'),
        require('@/assets/images/image_placeholder2.jpg'),
        require('@/assets/images/image_placeholder3bw.jpg'),
    ]


    export default {
        components: {
            WaveDivider
        },
        methods: {
            selectGoal(index){
                let goalAnimator = document.getElementById("goal-animator");
                let goalBg = document.getElementById("goals-bg");
                goalAnimator.classList.remove("slide-in");
                goalAnimator.classList.add("slide-out");

                goalBg.classList.remove("fade-in");
                goalBg.classList.add("fade-out");
                setTimeout(()=>{
                    this.currentGoal = index;
                    goalAnimator.classList.remove("slide-out");
                    goalAnimator.classList.add("slide-in");

                    goalBg.classList.remove("fade-out");
                    goalBg.classList.add("fade-in");
                },250);
                
            }
        },
        data: function(){
            return {
                goals: [
                    {
                        title: "Education",
                        content: `
                            Education is the holy grail when it comes to solutions to the large-scale problems we face as a nation and the world. However, we must note that there lies a huge difference between being literate and being learned. We aim to help the community achieve the latter.  We at Yasham firmly believe that each child has a vision that they deserve to unlock their fullest potential to achieve their ambitions. Education is the need of the hour; it is an absolute necessity. However, today quality education exists as a privilege. Our programs under education, aim to reduce this gap that has been created between the privileged and the underprivileged.
                            <br><br>
                            We use the latest tools and technology to supplement their formal education. We focus not only on proficiency in English, Science, Mathematical skills, Cognitive and Logical thinking but also on developing a refined Personality and a strong Value System. Art, Culture, Sports, and Field trips are also a part of the curriculum, promoting experiential learning. Ensuring that these children are prepared to face all kinds of challenges and are exposed to every field of excellence, we give priority to a well-rounded education. Apart from these formal measures of education, we at Yasham also consider gratitude, humility, and accountability as important factors of an educated individual. At Yasham, our goal for education does not end with employment, but with an enriched thought process.
                            `,
                        image: goalImages[0]
                    },
                    {
                        title: "Healthcare",
                        content: `
                            ‘A healthy body supports a healthy mind’<br><br>
                            This is an apt quote for studies that have revealed that proper nutrition and sound health play a major role in the holistic development of a child. Dental, Optical, and skin-related problems eventually degrade the academic performance of a student.
                            <br><br>
                            Underprivileged children cannot afford to have regular health checkups. Yasham provides healthcare facilities to these children and their families. To implement the medical facility, Yasham has tied up with various diagnostic centers and hospitals, to ensure that everyone gets the best treatment possible. These healthcare campaigns increase awareness within the families and help prevent the spread of chronic health problems. We ensure that these children have a good and healthy environment at home, which includes both mental and emotional well-being, giving them the stability they need to prepare themselves for a better tomorrow. 
                            `,
                        image: goalImages[2]
                    },
                    {
                        title: "Women Empowerment",
                        content: `
                            A woman is the backbone of the family. She supports it tirelessly and holds it together through situations of turmoil. While we might get blinded by the wave of modernity and reformation, there still exist families that lack awareness about the pivotal role of a female in the household. 
                            <br><br>
                            We at Yasham educate these families and make these women aware of their inherent strength and power through various livelihood programs and supplemental English Classes.  Thereby this mother becomes a role model for her child, filling them with pride and inspiration to see an independent and educated woman. These women are capable of instilling a sense of self-confidence and inculcating strong aspirations in their children. With the help of livelihood programs and English classes, their sense of self-importance grows manifold and helps the woman of the house, to take up the positions in the world outside, which she very well deserves.
                            `,
                        image: goalImages[1]
                    },
                    {
                        title: "Youth Mobilization",
                        content: `
                            The best investment opportunity is to invest in the youth. They will be our legacy; they will carry our ideals and they will be the next reformation.
                            <br><br>
                            Yasham has a series of programs that are running to equip the children and youth with the necessary skills needed to be gainfully employed. There are programs specifically for skill development, career placement, monitoring and counseling that help the students identify their goals and give them a road map to achieve them. We also take an active interest in finding the hidden potential in the children through periodic talent hunts.
                            <br><br>
                            Dreaming big was never the problem, it was always a solution. It takes courage and power to create a world with eyes closed. We work to create a world for the youth, which is just as beautiful, with their eyes open.
                            `,
                        image: goalImages[0]
                    },
                    {
                        title: "Community Enrichment",
                        content: `
                             A Good value system works towards the upliftment of a community. We practice our responsibility towards society as a whole through programs and practices at grass root levels. We offer counseling and elderly care to underprivileged families which in turn improve the mental makeup of the community. Alongside, we organize several cleanliness and beautification drives, which help in the overall development of the community.  We believe that a well-kept environment is a nest for a good healthy mind. 
                             <br><br>
                             While working, Yasham members make sure to imbibe qualities into all individuals that transform them into self-sufficient and learned members of a community. If one person inspires another to care for just one other individual, this world would be a much kinder place. We, at Yasham, leave no stone unturned in order to create a world full of magical humanity!
                            `,
                        image: goalImages[2]
                    },
                ],
                currentGoal: 0
            }
        }
    }
</script>

<style scoped>
    .goals-bg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 0;
        position: absolute;
    }

    .goals-inner{
        background: rgba(0,0,0,0.6);
        width: 100%;
        z-index: 0;
        min-height: 256px;
    }

    .slide-out{
        animation: slide-out 0.2s;
        animation-fill-mode: forwards;
    }

    .slide-in{
        animation: slide-in 0.2s;
        animation-fill-mode: forwards;
    }

    .fade-out{
        animation: fade-out 0.2s;
        animation-fill-mode: forwards;
    }

    .fade-in{
        animation: fade-in 0.2s;
        animation-fill-mode: forwards;
    }

    .carousel-select{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 32px;
    }

    .carousel-select span{
        display: block;
        width: 12px;
        height: 12px;
        background: #FFF;
        border-radius: 16px;
        margin: 0px 8px;
        opacity: 0.5;
        transform: scale(0.75);
        cursor: pointer;
        transition: all 0.25s;
    }

    .carousel-select span[selected]{
        opacity: 1;
        transform: scale(1);
    }

    @keyframes slide-in {
        from {
            opacity: 0;
            transform: translateX(-128px);
        }
        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }

    @keyframes slide-out {
        from {
            opacity: 1;
            transform: translateX(0px);
        }
        to {
            opacity: 0;
            transform: translateX(128px);
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
</style>